import React from "react";
import FooterThree from "../components/Footers/FooterThree";
import HeaderOne from "../components/Headers/HeaderOne";
import ScrollTop from "../components/ScrollTop/ScrollTop";

const Layout = ({ children, header = 1, footer, className, mainClassName }) => {
  return (
    <>
      <ScrollTop />

      <div className={className}>
        <HeaderOne />
        <main className={mainClassName}>{children}</main>
       <FooterThree />
      </div>
    </>
  );
};

export default Layout;
