import React from "react";
import InnerContactForm from "./InnerContactForm";
import InnerContactInfo from "./InnerContactInfo";

const InnerContactArea = () => {
  return (
    <section className="inner-contact-area">
      <div className="container">
        <div className="inner-contact-wrap">
          <div className="row">
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                 className="col-xl-12 col-lg-12">

              <div className="inner-contact-form-wrap">
                <InnerContactForm/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InnerContactArea;
