import React from "react";
import { Link } from "react-router-dom";

const AgencyArea = () => {
  return (
    <section className="agency-area pt-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="agency-img text-center">
              <img style={{borderRadius: 8}} src="/img/images/agency_img.png" alt="" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="agency-content">
              <div className="section-title title-style-two mb-20">
                <span className="sub-title">بهترین انتخاب برای سرمایه‌گذاری شما</span>
                <h2 className="title">پیشرو در سرمایه‌گذاری آنلاین از سال ۲۰۲۳</h2>
              </div>
              <p className="info-one">
                از سال ۲۰۲۳، ما با ارائه خدمات منحصر به فرد در زمینه مدیریت دارایی و سرمایه‌گذاری در بازار فارکس، به عنوان یکی از پیشروان این حوزه شناخته شده‌ایم.
              </p>
              <p className="info-two">
                با تیمی متخصص و حرفه‌ای
                . پلتفرم ما با ارائه راهکارهای نوین و مطمئن، مسیر موفقیت مالی شما را هموار می‌کند.
              </p>

              <Link to="/about-us" className="btn">
                بیشتر <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="agency-shape-wrap">
        <img src="/img/images/agency_shape01.png" alt="" />
        <img src="/img/images/agency_shape02.png" alt="" />
        <img src="/img/images/agency_shape03.png" alt="" />
        <img src="/img/images/agency_shape04.png" alt="" />
      </div>
    </section>
  );
};

export default AgencyArea;
