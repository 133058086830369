import React from "react";
import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import Layout from "../../layouts/Layout";

const TermsPages = () => {
    return (
        <Layout header={1} footer={3} className="" mainClassName="">


            <div className="terms-condition">
                <h1 style={{textAlign: "center"}}>قوانین و مقررات جذب سرمایه و ترید در فارکس</h1>

                <h2>قوانین و مقررات جذب سرمایه و ترید در فارکس</h2>
                <p>ترید در فارکس تهیه شده است. با قبول این قوانین، کاربران موافقت خود را با شرایط مذکور اعلام می‌کنند.</p>

                <h2>مقدمه</h2>
                <p>این قوانین و مقررات جهت شفاف سازی شرایط همکاری میان کاربران و وبسایت طوفان ترید در فارکس تهیه شده است. با قبول این قوانین،
                    کاربران موافقت خود را با شرایط مذکور اعلام میکنند .</p>
                <h2>تعاریف</h2>
                <p>سایت: سایتی که خدمات ترید در فارکس را ارائه می‌دهد.</p>
                <p>کاربر: شخص حقیقی یا حقوقی که تمایل به سرمایه‌گذاری و همکاری با سایت دارد.</p>
                <p>سرمایه‌گذار: کاربرانی که با واریز وجه به آدرس کیف پول مشخص شده در سایت، سرمایه‌گذاری می‌کنند.</p>
                <p>ترید: خرید و فروش ارزهای خارجی در بازار فارکس به منظور کسب سود.</p>

                <h2>شرایط سرمایه‌گذاری</h2>
                <p>کاربران باید حداقل ۱۸ سال سن داشته باشند.</p>
                <p>کاربران باید مدارک شناسایی معتبر و مستندات مالی لازم را ارائه دهند.</p>
                <p>حداقل مبلغ سرمایه‌گذاری تعیین شده توسط سایت رعایت شود.</p>

                <h2>نحوه سرمایه‌گذاری</h2>
                <p>کاربران پس از ثبت نام و تأیید هویت، مبلغ مورد نظر خود را به حساب کیف پول مشخص شده در سایت واریز می‌کنند.</p>
                <p>کاربر موظف است رسید واریز و اطلاعات مربوط به سرمایه‌گذاری را در قسمت مشخص شده در سایت بارگذاری نماید.</p>

                <h2>نحوه ترید و تقسیم سود</h2>
                <p>سایت مدیریت و انجام معاملات را بر عهده دارد.</p>
                <p>سود حاصل از معاملات پس از کسر هزینه‌ها و کارمزدهای مشخص، به نسبت تعیین شده بین سایت و سرمایه‌گذار تقسیم می‌شود.</p>
                <p>پرداخت سود به صورت ماهیانه و در انتهای ماه میلادی تعیین می‌شود.</p>

                <h2>ریسک‌ها و مسئولیت‌ها</h2>
                <p>کاربر با آگاهی کامل از ریسک‌های مرتبط با ترید در فارکس سرمایه‌گذاری می‌کند.</p>
                <p>سایت هیچ تضمینی در مورد سودآوری ندارد و مسئولیت زیان‌های احتمالی بر عهده کاربر است.</p>
                <p>سایت تلاش خود را جهت مدیریت بهینه سرمایه و کاهش ریسک به کار می‌گیرد.</p>

                <h2>درخواست برداشت</h2>
                <p>کاربر می‌تواند در پایان هر ماه میلادی و پس از تعیین سود و زیان مطابق مانده کیف پول خود درخواست برداشت را در سایت اعلام کند.</p>
                <p>با توجه به شرایط بازار ممکن است تا یک هفته زمان ببرد.</p>

                <h2>حفظ حریم خصوصی</h2>
                <p>سایت متعهد به حفظ حریم خصوصی و اطلاعات شخصی کاربران است.</p>
                <p>اطلاعات کاربران تنها با رضایت ایشان یا به حکم قانون به اشخاص ثالث ارائه می‌شود.</p>

                <h2>تغییرات در قوانین و مقررات</h2>
                <p>سایت حق تغییر قوانین و مقررات را دارد و موظف است تغییرات را از طریق وبسایت یا ایمیل به اطلاع کاربران برساند.</p>
                <p>کاربران موظف به مطالعه و قبول تغییرات جدید هستند.</p>

                <h2>قوانین حقوقی</h2>
                <p>در صورت بروز هرگونه اختلاف، موضوع به داوری مشخص شده از طرف سایت ارجاع خواهد شد.</p>

                <h2>پذیرش قوانین</h2>
                <p>کاربران با ثبت نام و سرمایه‌گذاری در سایت، پذیرش کامل این قوانین و مقررات را اعلام می‌کنند.</p>
                <p>کاربران با پذیرش این قوانین و مقررات، حق هرگونه اعتراض به تغییرات و به‌روزرسانی‌های آینده این قوانین را از خود سلب می‌نمایند.</p>
                <p>سایت حق هرگونه استفاده، تغییر و به‌روزرسانی این قوانین را برای خود محفوظ می‌دارد.</p>
            </div>

        </Layout>
    );
};

export default TermsPages;
