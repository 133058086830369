import React from "react";
import { Link } from "react-router-dom";

const BlogAreaThreeItem = (props) => {
  return (
    <div className="blog-item-two blog-item-three">
      <div className="blog-thumb-two">
        <Link to={props.item?.link}>
          <img style={{width: "100%", height: 300}} src={props?.item?.better_featured_image?.source_url} alt="" />
        </Link>
      </div>
      <div className="blog-content-two">
        <h2 className="title">
          <Link to={props.item?.link}>{props.item?.title?.rendered}</Link>
        </h2>
        <p>
          <Link to={`https://www.toofan.trade/fa/blogs/${props?.item?.id}`}>بیشتر</Link>
        </p>
      </div>
    </div>
  );
};

export default BlogAreaThreeItem;
