import React from "react";
import HistoryAreaRoadmap from "./HistoryAreaRoadmap";

const HistoryArea = () => {
  return (
    <section className="history-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title white-title text-center mb-45">
              <span className="sub-title">شرکت ما</span>
              <h2 className="title">درباره ما</h2>
              <p>
                رضایت شما مهم‌ترین هدف ماست و تمام تلاش خود را برای جلب اعتماد و رضایت شما به کار می‌بریم.
              </p>
            </div>
          </div>
        </div>
        <div className="history-inner">
          <div className="history-img">
            <img src="/img/images/history_img.png" alt="" />
          </div>
          <div className="row g-0 justify-content-end">
            <div className="col-lg-6">
              <div className="history-content">
                <h2 className="title">
                  ایجاد یک محیط امن و نوآورانه برای سرمایه‌گذاری
                </h2>
                <p>
                  ما در طوفان ترید، متعهد هستیم که بهترین خدمات را در زمینه سرمایه‌گذاری در بازار فارکس به شما ارائه دهیم. از سال ۲۰۲۳، به عنوان یک شرکت آنلاین پیشرو، با راهکارهای نوآورانه و حرفه‌ای، به مشتریانمان کمک می‌کنیم تا به اهداف مالی خود دست یابند. تیم ما از متخصصان با تجربه و متعهدی تشکیل شده است که همواره در تلاش برای بهبود و ارتقاء خدمات خود هستند.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="history-shape-wrap">
        <img src="/img/images/history_shape01.png" alt="" />
        <img src="/img/images/history_shape02.png" alt="" />
      </div>
    </section>
  );
};

export default HistoryArea;
