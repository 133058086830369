import React from "react";

import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import HistoryArea from "../../components/HistoryArea/HistoryArea";
import Layout from "../../layouts/Layout";
import Faq from "../../components/Faq/Faq";

const AboutUs = () => {
  return (
    <Layout header={1} footer={1} className="" mainClassName="">
      <BreadcrumbArea
        title="درباره ما"
        subtitle={"درباره ما"}
        className={"pt-175 pb-140"}
      />
      <HistoryArea />
        <Faq />
    </Layout>
  );
};

export default AboutUs;
