import React, {useEffect, useState} from "react";
import BlogAreaThreeItem from "./BlogAreaThreeItem";
import { Link } from "react-router-dom";

const BlogAreaThree = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://contentapi.toofan.trade/wp-json/wp/v2/posts?_embed&lang=fa&per_page=100');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPosts(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <section className="blog-area-two blog-area-three pt-110 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title title-style-two text-center mb-60">
              <span className="sub-title">بلاگ</span>
              <h2 className="title">پست‌های اخیر</h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {posts.slice(0, 3).map((x, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-10">
              <BlogAreaThreeItem item={x} />
            </div>
          ))}
        </div>

        <div className="read-more-btn text-center mt-30">
          <a href="https://www.toofan.trade/fa/blogs/" target={"_blank"} className="btn">
            بیشتر <span></span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default BlogAreaThree;
