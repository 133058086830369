import React from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Iframe from "react-iframe";

const SliderAreaItem = () => {
  return (
    <div className="single-slider wow fadeInUp">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <div
              className="slider-img text-end"
              data-animation="fadeInRight"
              data-delay=".8s"
            >
              <img src="/img/slider/slider_img01.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="slider-content">
              <h4 className="title" >
                 رویای بزرگ، سود مطمئن
              </h4>
              <p data-animation="fadeInUp" data-delay=".4s">
                هج فاند به شما کمک می‌کند تا با سرمایه‌گذاری مطمئن، به رویاهای بزرگتان دست یابید و درآمد ماهانه کسب کنید.
              </p>
              <div className="slider-btn">
                <a
                  href="https://app.hedgefund.toofan.trade/auth/login"
                  className="btn"
                >
                  شروع کنید <span></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderAreaItem;
