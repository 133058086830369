import React from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import ServiceAreaThreeItem from "./ServiceAreaThreeItem";

const ServiceAreaThree = () => {
  const services = [
    {
      title: "پشتیبانی 24 ساعته",
      desc: " در هر زمانی تیم پشتیبانی ما آماده یاری رساندن به شماست،",
      src: "/img/icon/h3_services_icon01.png",
    },
    {
      title: "پلتفرم امن",
      desc: " امنیت سرمایه‌های شما اولویت ماست. با اطمینان سرمایه‌گذاری کنید. ",
      src: "/img/icon/h3_services_icon02.png",
    },
    {
      title: "درآمد ماهانه",
      desc: "شما می‌توانید با اطمینان از سرمایه‌گذاری خود، هر ماه درآمد ۵ الی ۳ درصد به صورت تضمینی دریافت کنید.",
      src: "/img/icon/h3_services_icon03.png",
    },
  ];

  const slick_settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="services-area-three">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-9">
            <div className="section-title title-style-two text-center mb-45">
              <span className="sub-title">ما چیکار میکنیم</span>
              <h5 className="title">
                ماموریت ما این است که با ارائه بهترین خدمات و راهکارهای مالی، شما را در مسیر رسیدن به اهداف و رویاهای مالی‌تان همراهی کنیم. ما به شما کمک می‌کنیم تا با آرامش خاطر سرمایه‌گذاری کنید و از درآمد ماهانه و مطمئن بهره‌مند شوید. اینجا، هر قدم شما به سوی آینده‌ای روشن‌تر و موفق‌تر برداشته می‌شود.
              </h5>
            </div>
          </div>
        </div>

        <div className="services-item-wrap">
          <div className="row services-active services-active-three">
            <SlickSlider settings={slick_settings}>
              {services.map((x, index) => (
                <div key={index} className="col-lg-4">
                  <ServiceAreaThreeItem item={x} />
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>

      <div className="services-shape">
        <img src="/img/images/h3_services_shape.png" alt="" />
      </div>
    </section>
  );
};

export default ServiceAreaThree;
