import React from "react";

const TestimonialAreaThreeItem = (props) => {

  return (
    <div className="swiper-slide">
      <div className="testimonial-item-three">
        <div className="testimonial-thumb-three">
          <img src={`/img/images/testimonial_avatar0${props.id}.png`} alt="" />
        </div>

        <div className="testimonial-content-three">
          <h4 className="title">{props?.data?.name} </h4>

          <span>کاربر</span>

          <p>{props?.data?.desc}</p>

        </div>
      </div>
    </div>
  );
};

export default TestimonialAreaThreeItem;
