import React from "react";
import { Link } from "react-router-dom";
import CounterAreaTwoItem from "./CounterAreaTwoItem";

const CounterAreaTwo = () => {
  const counter_items = [
    {
      src: "/img/icon/counter_icon02.png",
      title: "دارایی",
      suffix: "",
      amount: 2000
    },
    {
      src: "/img/icon/counter_icon03.png",
      title: "کاربرها",
      amount: 2000
    },
  ];

  return (
    <section className="counter-area-two pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="counter-item-wrap-two">
              <ul className="list-wrap">
                {counter_items.map((x, index) => (
                  <li key={index}>
                    <CounterAreaTwoItem item={x} amount={1 + 50} />
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="counter-content">
              <div className="section-title title-style-two mb-20">
                <span className="sub-title">دستاوردهای ما</span>
                <h2 className="title">
                  بیش از ۳۰۰۰ سرمایه‌گذاری موفق و همچنان در حال رشد
                </h2>
              </div>
              <p className="info">
                تا به امروز، ما بیش از ۳۰۰۰ پروژه سرمایه‌گذاری موفق را به انجام رسانده‌ایم و این تعداد هر روز در حال افزایش است. افراد بیشتری به ما اعتماد می‌کنند و سرمایه‌های خود را با آرامش خاطر در پلتفرم ما قرار می‌دهند. با تضمین امنیت بالا و ارائه درآمد ماهانه، ما به شما اطمینان می‌دهیم که سرمایه‌گذاری با ما، تصمیمی هوشمندانه و پرسود خواهد بود.
              </p>


            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterAreaTwo;
