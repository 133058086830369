import React from "react";

const Faq = () => {
  return (
    <section className="faq-area pt-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="faq-img">
              <img src="/img/images/faq_img.png" alt="" />
              <img src="/img/images/faq_img02.png" alt="" />
            </div>
            <div className="faq-content">
              <div className="section-title title-style-two mb-20">
                <h2 className="title">
                  سوالات متداول
                </h2>
              </div>
              <p>
                همه چیزهایی که باید درباره سرمایه‌گذاری با ما بدانید
                در این بخش، به سوالات رایج و مهم شما در مورد سرمایه‌گذاری در پلتفرم ما پاسخ داده‌ایم. هدف ما این است که شما با اطمینان و آرامش خاطر بیشتری سرمایه‌گذاری کنید. اگر سوال دیگری دارید یا نیاز به مشاوره بیشتری دارید، تیم پشتیبانی ما همیشه آماده کمک به شماست.
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="accordion faq-wrap" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    چگونه می‌توانم در پلتفرم شما سرمایه‌گذاری کنم؟

                  </button>
                </h2>

                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      برای سرمایه‌گذاری در پلتفرم ما، ابتدا باید ثبت‌نام کنید. پس از ثبت‌نام و تکمیل اطلاعات لازم، می‌توانید مبلغ مورد نظر خود را به حساب سرمایه‌گذاری واریز کنید و از درآمد ماهانه ۵ الی ۳ درصد در ماه بهره‌مند شوید. تیم پشتیبانی ما نیز در هر مرحله آماده کمک به شماست.

                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    آیا سرمایه‌گذاری در پلتفرم شما امن است؟

                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      بله، امنیت سرمایه‌های شما اولویت اصلی ماست. ما از تکنولوژی‌های پیشرفته و سیستم‌های امنیتی قوی برای حفاظت از سرمایه‌های شما استفاده می‌کنیم. تمامی تراکنش‌ها و سرمایه‌گذاری‌ها با بالاترین استانداردهای امنیتی انجام می‌شود تا شما با آرامش خاطر سرمایه‌گذاری کنید.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    چگونه می‌توانم درآمد ماهانه خود را دریافت کنم؟

                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      درآمد ماهانه شما به صورت ماهانه به حساب کاربری شما واریز می‌شود. شما می‌توانید در هر اول هر ماه ، سود خود را برداشت کنید یا مجدداً در پلتفرم سرمایه‌گذاری کنید. تیم پشتیبانی ما نیز همواره آماده پاسخگویی به سوالات شما در این زمینه است.

                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    چه نوع پشتیبانی از مشتریان ارائه می‌دهید؟

                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      ما پشتیبانی 24 ساعته را برای مشتریان خود فراهم کرده‌ایم. تیم پشتیبانی ما در هر زمان از شبانه‌روز آماده پاسخگویی به سوالات و حل مشکلات شماست. شما می‌توانید از طریق تماس تلفنی، ایمیل یا چت آنلاین با ما در ارتباط باشید و از خدمات مشاوره‌ای و پشتیبانی ما بهره‌مند شوید.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-shape-wrap">
        <img src="/img/images/faq_shape.png" alt="" />
      </div>
    </section>
  );
};

export default Faq;
